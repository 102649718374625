import React, { FC } from 'react'
import { Space, Avatar } from 'antd'
import LogoutButton from './LogoutButton'
import { useUserStore } from '../hooks/user'
import { UserOutlined } from '@ant-design/icons'

const UserPanel: FC = () => {
  const userStore = useUserStore()

  return (
    <Space>
      <Avatar icon={<UserOutlined />} />
      {userStore.profile?.name}
      <LogoutButton />
    </Space>
  )
}

export default UserPanel
