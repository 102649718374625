import React from 'react'
import { useParams } from 'react-router-dom'
import { Space } from 'antd'
import { useMeetingDetailQuery } from '../../graphql'
import MeetingCard from './MeetingCard'
import ScheduleListCard from './ScheduleListCard'
import ErrorView from '../../components/ErrorView'

const ExceptionDetail: QCPage = () => {
  const { meetingId } = useParams()

  const [{ data, fetching, error }, refetchDetail] = useMeetingDetailQuery({
    variables: {
      meetingId
    }
  })
  const meetingDetail = data?.meetingDetail
  const { schedules = [], ...orderDetail } = (meetingDetail?.carOrders ||
    {}) as {
    [key: string]: any
  }

  if (error) {
    return (
      <ErrorView
        onRefresh={refetchDetail}
        title={error.name}
        message={error.message}
      />
    )
  }
  return (
    <Space direction='vertical'>
      <MeetingCard fetching={fetching} data={meetingDetail} />
      <ScheduleListCard
        meeting={meetingDetail}
        fetching={fetching}
        orders={
          (meetingDetail?.carOrders as {
            [key: string]: any
          }[]) || []
        }
      />
    </Space>
  )
}

export default ExceptionDetail
