import React, { FC, useEffect } from 'react'
import { Form, Row, Col, Button, Input } from 'antd'
import { FormProps } from 'antd/lib/form'
import { Store } from 'antd/lib/form/interface'
import { ButtonProps } from 'antd/lib/button'

interface SearchField {
  name: string
  label: string
  defaultValue?: string
  span?: number
  render: (form: Store) => React.ReactNode
}

export interface SearchPanelProps {
  fields?: SearchField[]
  onChange?: FormProps['onFinish']
  onFinish?: FormProps['onFinish']
}

const SearchPanel: FC<SearchPanelProps> = ({
  fields = [],
  onChange,
  onFinish
}) => {
  const [form] = Form.useForm()
  const __onFinish: FormProps['onFinish'] = (values) => {
    onFinish && onFinish(values)
  }

  const __onChange: FormProps['onValuesChange'] = (_, values) => {
    onChange && onChange(values)
  }

  const __onReset: ButtonProps['onClick'] = () => {
    form.resetFields()
    onChange && onChange({})
  }

  return (
    <Form
      form={form}
      name='advanced_search'
      className='ant-advanced-search-form'
      onFinish={__onFinish}
      onValuesChange={__onChange}
    >
      <Row gutter={22}>
        {fields.map((field) => {
          return (
            <Col span={field.span || 24 / fields.length} key={field.name}>
              <Form.Item name={field.name} label={field.label}>
                {field.render(form)}
              </Form.Item>
            </Col>
          )
        })}
        <Button type='primary' htmlType='submit'>
          搜索
        </Button>
        <Button onClick={__onReset}>重置</Button>
      </Row>
    </Form>
  )
}

export default SearchPanel
