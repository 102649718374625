import React, { FC, useEffect } from 'react'
import { Button, Modal } from 'antd'

import {
  useCarServiceOfferPriceQuery,
  CarServiceOfferPriceQuery
} from '../graphql'

export type AcquireCarOfferPriceButtonProps = {
  serviceName?: string
  cityName?: string
  carType?: string
  supplier?: string
  onResult?: (price: CarServiceOfferPriceQuery['price'][0] | undefined) => void
}

const AcquireCarOfferPriceButton: FC<AcquireCarOfferPriceButtonProps> = ({
  serviceName = '',
  supplier = '',
  cityName = '',
  carType = '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onResult = () => {}
}) => {
  const [{ data, fetching }, fetchOffer] = useCarServiceOfferPriceQuery({
    pause: true,
    variables: {
      serviceName,
      supplier,
      cityName,
      carType
    }
  })

  useEffect(() => {
    data?.price[0] && onResult(data.price[0])
  }, [data?.price])

  return serviceName && supplier && cityName && carType ? (
    <>
      <Button loading={fetching} onClick={fetchOffer}>
        自动报价
      </Button>
    </>
  ) : null
}

export default AcquireCarOfferPriceButton
