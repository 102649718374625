import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { createClient, Provider, dedupExchange, fetchExchange } from 'urql'
import 'antd/dist/antd.less'
import zhCN from 'antd/es/locale/zh_CN'

import App from './App'
import { StoreProvider } from './hooks/user'
import { ConfigProvider } from 'antd'

const client = createClient({
  url: process.env.GRAPHQL_URL || 'http://localhost:4000/graphql',
  fetchOptions: () => {
    return {
      headers: {
        token: localStorage.getItem('bms-login-token') || ''
      }
    }
  },
  exchanges: [
    dedupExchange,
    // NOTE: Leave out to disable caching:
    // cacheExchange
    fetchExchange
  ]
})

ReactDOM.render(
  <ConfigProvider locale={zhCN}>
    <StoreProvider>
      <Provider value={client}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </StoreProvider>
  </ConfigProvider>,
  document.getElementById('app')
)
