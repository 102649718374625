/* eslint-disable react/display-name */
import React, { FC } from 'react'
import { Table, Row, Col, Divider, Tag } from 'antd'
import dayjs from 'dayjs'
import omit from 'lodash.omit'
import { Link } from 'react-router-dom'
import _ from 'lodash'

import {
  CAR_TYPE,
  CAR_SERVICE_TYPE,
  CAR_SERVICE_STATUS,
  CAR_SUPPLIER
} from '../../constant/car'
import ScheduleRiskItem from '../../components/ScheduleRiskItem'
import AccountInfo from '../../components/AccountInfo'
import { normalizePrice } from '../../lib/normalize'
import {
  DownCircleOutlined,
  UpCircleOutlined,
  ArrowDownOutlined,
  ArrowUpOutlined
} from '@ant-design/icons'

type CarOrdersTableProps = {
  orders: any[]
  quotationTotal: number
}

const CarOrdersTable: FC<CarOrdersTableProps> = ({
  orders,
  quotationTotal
}) => {
  const schedules = orders.reduce<any[]>(
    (s, c) =>
      s.concat(
        // c.schedules.map((schedule: any) => {
        //   return { ...schedule, orderInfo: omit(c, 'schedule') }
        // })
        // 只取每个订单中的第一个行程信息
        { ...c.schedules[0], orderInfo: omit(c, 'schedules') }
      ),
    []
  )

  const totalCost = _.sumBy(
    (orders as any[]) || [],
    (order) => order?.totalCost || 0
  )

  const totalPO =
    orders?.filter((order: any) => order?.totalCost > 0).length * 3

  const totalTax = ((totalCost + totalPO) * 6) / 100

  const total = totalCost + totalPO + totalTax

  const diff = total - quotationTotal

  return (
    <Row>
      <Table
        size='small'
        dataSource={schedules}
        rowKey='id'
        columns={[
          {
            title: '城市',
            dataIndex: 'orderInfo',
            render: (o) => o?.cityName
          },
          {
            title: '上车时间',
            dataIndex: 'departureTime',
            render: (d) => dayjs(d).format('YYYY-MM-DD HH:mm:ss')
          },
          {
            title: '上车地点',
            dataIndex: 'origin',
            render: (o) => o?.address
          },
          {
            title: '下车地点',
            dataIndex: 'destination',
            render: (d) => d?.address
          },
          {
            title: '用车类型',
            dataIndex: 'orderInfo',
            render: (o) =>
              o?.serviceType &&
              CAR_SERVICE_TYPE[o.serviceType as keyof typeof CAR_SERVICE_TYPE]
          },
          {
            title: '服务类型',
            dataIndex: 'orderInfo',
            render: (o) =>
              CAR_SUPPLIER[o?.supplier as keyof typeof CAR_SUPPLIER]
          },
          {
            title: '车型',
            dataIndex: 'model',
            render: (model) => {
              return model && CAR_TYPE[model as keyof typeof CAR_TYPE]
            }
          },
          {
            title: '授权代表',
            dataIndex: 'orderInfo',
            render: (o) => <AccountInfo accountId={o?.userId} field={'name'} />
          },
          {
            title: '授权代表手机号',
            dataIndex: 'orderInfo',
            render: (o) => (
              <AccountInfo accountId={o?.userId} field={'mobile'} />
            )
          },
          {
            title: '状态',
            dataIndex: 'orderInfo',
            render: (o) =>
              CAR_SERVICE_STATUS[o?.status as keyof typeof CAR_SERVICE_STATUS]
          },
          {
            title: '时间异常',
            dataIndex: 'scheduleId',
            render: (scheduleId, schedule) => (
              <ScheduleRiskItem
                type='TIME_ABNORMAL'
                scheduleId={scheduleId}
                risks={schedule.risks}
              />
            )
          },
          {
            title: '行程异常',
            dataIndex: 'scheduleId',
            render: (scheduleId, schedule) => (
              <ScheduleRiskItem
                type={[
                  'GET_IN_ABNORMAL',
                  'GET_OFF_ABNORMAL',
                  'DISTANCE_ABNORMAL'
                ]}
                scheduleId={scheduleId}
                risks={schedule.risks}
              />
            )
          },
          {
            title: '费用异常',
            dataIndex: 'scheduleId',
            render: (scheduleId, schedule) => (
              <ScheduleRiskItem
                type='FEE_ABNORMAL'
                scheduleId={scheduleId}
                risks={schedule.risks}
              />
            )
          },
          {
            title: '乘车人异常',
            dataIndex: 'scheduleId',
            render: (scheduleId, schedule) => (
              <ScheduleRiskItem
                type='PASSENGER_ABNORMAL'
                scheduleId={scheduleId}
                risks={schedule.risks}
              />
            )
          },
          {
            title: '操作',
            dataIndex: 'id',
            render: (id, schedule) => (
              <Link
                to={`/carOrder/${schedule.orderInfo?.orderId}/schedule/${id}`}
              >
                详情
              </Link>
            )
          }
        ]}
      ></Table>
      <Col span={24}>
        车辆实际汇总价格: {normalizePrice(totalCost)}
        <Divider type='vertical' />
        PO实际价格: {normalizePrice(totalCost + totalPO)}{' '}
        <Divider type='vertical' />
        实际税费: {normalizePrice(totalTax)} <Divider type='vertical' />
        实际合计: {normalizePrice(totalCost + totalPO + totalTax)}{' '}
        <Tag color={diff < 0 ? 'green' : 'red'}>
          {diff < 0 ? '+' : '-'}
          {normalizePrice(
            Math.abs(totalCost + totalPO + totalTax - quotationTotal)
          )}
        </Tag>
      </Col>
    </Row>
  )
}

export default CarOrdersTable
