/* eslint-disable @typescript-eslint/camelcase */
export const CAR_TYPE = {
  economic: '经济型',
  /**
   * 舒适型
   */
  comfortable: '舒适型',
  /**
   * 商务型
   */
  business: '商务型',
  /**
   * 豪华型
   */
  luxurious: '豪华型',
  /**
   * 小巴
   */
  minibus: '小巴',
  /**
   * 中巴
   */
  midibus: '中巴',
  /**
   * 大巴
   */
  bigbus: '大巴'
}

export const CAR_SUPPLIER = {
  /**
   * 滴滴
   */
  didi: '滴滴',
  /**
   * 线下车队
   */
  offlineteam: '线下车队',
  /**
   * 神州
   */
  shenzhou: '神州'
}

export const CAR_FROM = {
  /**
   * 网约车
   */
  didi: '网约车',
  /**
   * 线下车队
   */
  offlineteam: '线下车队',
  /**
   * 网约车
   */
  shenzhou: '网约车'
}
export const CAR_SERVICE_TYPE = {
  /**
   * 立即用车
   */
  now: '立即用车',

  /**
   * 预约用车
   */
  reserve: '预约用车',
  /**
   * 包车
   */
  rent: '包车',
  /**
   * 接机
   */
  pick_up: '接机',
  /**
   * 送机
   */
  drop_off: '送机'
}

export const CAR_SERVICE_STATUS = {
  /**
   * 派车中
   */
  PF_TRAVEL_DISPATCHING: '派车中',
  /**
   * 派车失败
   */
  PF_TRAVEL_FAILED_IN_DISPATCHING: '派车失败',
  /**
   * 派车成功
   */
  PF_TRAVEL_DISPATCHED: '派车成功',
  /**
   * 司机已到达
   */
  PF_TRAVEL_DRIVER_ARRIVED: '司机已到达',
  /**
   * 服务中
   */
  PF_TRAVEL_SERVING: '服务中',
  /**
   * 等待消费确认
   */
  PF_TRAVEL_TO_BE_CONFIRMED: '等待消费确认',
  /**
   * 已完成、已支付
   */
  PF_TRAVEL_FINISHED: '已完成、已支付',
  /**
   * 已取消（无违约金）
   */
  PF_TRAVEL_CANCELED: '已取消（无违约金）',
  /**
   * 已取消, 取消费用待确认
   */
  PF_TRAVEL_CANCELED_TO_BE_CONFIRMED: '已取消, 取消费用待确认',
  /**
   * 已取消（有违约金）
   */
  PF_TRAVEL_CANCELED_WITH_COST: '已取消（有违约金）'
}
