import React, { FC, useState, ReactText, useEffect } from 'react'
import { Form, Table, Button, message, Divider, Row, Col, Space } from 'antd'
import { useParams } from 'react-router-dom'
import { FormProps, FormItemProps, FormInstance } from 'antd/lib/form'
import { useClient } from 'urql'
import {
  useMeetingOrdersTable,
  formatQuotations,
  generateTotalObj
} from './util'
import {
  MeetingDetailQuery,
  useBatchSaveQuotationMutation,
  UpMeetingStatus,
  UpMeetingQuotationStatus,
  MeetingOrderInfo,
  useUploadSignedPaperMutation,
  CarServiceOfferPriceQuery,
  CarServiceOfferPriceQueryVariables,
  CarServiceOfferPriceDocument,
  useUploadPoMutation
} from '../../graphql'
import { TableRowSelection } from 'antd/lib/table/interface'
import UploadButton from '../../components/UploadButton'
import { DownloadOutlined } from '@ant-design/icons'
import { normalizePrice } from '../../lib/normalize'
import DownloadQuotationBill from '../../components/DownloadQuotationBill'

const OrdersTable: FC<{
  meetingDetail: MeetingDetailQuery['meetingDetail']
  onQuotationSuccess?: () => void
  calculatedTotal?: (total: number) => void
  // eslint-disable-next-line @typescript-eslint/no-empty-function
}> = ({ meetingDetail, onQuotationSuccess = () => {}, calculatedTotal }) => {
  const { action } = useParams()
  const [quotationForm] = Form.useForm()
  const urqlClient = useClient()

  const [, uploadPO] = useUploadPoMutation()

  const [selectedRowKeys, updateSelectedRowKeys] = useState<ReactText[]>([])

  const canQuotation =
    action === 'quotation' &&
    meetingDetail?.status === UpMeetingStatus.UMeetingQuotation &&
    meetingDetail?.subStatus === UpMeetingQuotationStatus.UAwaitQuotation

  const { columns, orders, totalObj } = useMeetingOrdersTable({
    detail: meetingDetail,
    canQuotation,
    onPriceApply(price, field) {
      quotationForm.setFieldsValue({
        [field]: parseFloat(price || '0')
      })
    }
  })

  const [{ fetching }, saveQuotation] = useBatchSaveQuotationMutation()

  const [, uploadSignPaper] = useUploadSignedPaperMutation()

  const uploadSignPaperHandler = async (url: string | undefined) => {
    if (url) {
      const { data, error } = await uploadSignPaper({
        meetingId: meetingDetail?.id!,
        url
      })
      if (!data?.uploadRes || error) {
        throw new Error(error?.graphQLErrors[0]?.message || '上传失败')
      }
    }
  }

  const onSubmitQuotation: FormProps['onFinish'] = async (values) => {
    const result = formatQuotations(orders, values)

    if (meetingDetail?.id) {
      const { error, data } = await saveQuotation({
        meetingId: meetingDetail.id,
        quotations: result
      })

      if (error || !data?.quotationBatchSave) {
        message.error('报价失败')
      } else if (data?.quotationBatchSave) {
        message.success('报价成功')
        onQuotationSuccess()
      }
    }
  }

  const rowSelection:
    | TableRowSelection<DeepPartial<MeetingOrderInfo>>
    | undefined = canQuotation
    ? {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(
            `selectedRowKeys: ${selectedRowKeys}`,
            'selectedRows: ',
            selectedRows
          )
          updateSelectedRowKeys(selectedRowKeys)
        }
      }
    : undefined

  const autoQuote = async (
    form: Omit<FormInstance, 'scrollToField' | '__INTERNAL__'>
  ) => {
    const values = form.getFieldsValue()
    orders.forEach(async (order, index) => {
      if (order.id && selectedRowKeys.includes(order.id)) {
        const { data } = await urqlClient
          .query<CarServiceOfferPriceQuery, CarServiceOfferPriceQueryVariables>(
            CarServiceOfferPriceDocument,
            {
              serviceName: order?.attributes?.type as string,
              carType: order?.attributes?.carType as string,
              cityName: values[`quotation${index}.cityName`],
              supplier: values[`quotation${index}.supplier`]
            }
          )
          .toPromise()

        data?.price[0] &&
          form.setFieldsValue({
            [`quotation${index}.unitPrice`]: data?.price[0].price
          })
      }
    })
  }

  const uploadPOHandler = async (url: string | undefined) => {
    if (url) {
      const { data, error } = await uploadPO({
        meetingId: meetingDetail?.id!,
        url
      })
      if (!data?.uploadRes || error) {
        throw new Error(error?.graphQLErrors[0]?.message || '上传失败')
      }
      onQuotationSuccess()
    }
  }

  return (
    <>
      <Form
        layout='horizontal'
        form={quotationForm}
        name='quotation'
        onFinish={onSubmitQuotation}
        onFinishFailed={() => message.error('提交失败')}
      >
        <Table
          dataSource={orders}
          bordered
          rowKey='id'
          columns={columns}
          pagination={false}
          rowSelection={rowSelection}
        />
        <Divider />
        <Row style={{ marginTop: 20 }} gutter={12} align='middle'>
          <Col flex='auto'>
            <Form.Item shouldUpdate={true} noStyle>
              {(form) => {
                let total = totalObj.carTotal

                if (canQuotation) {
                  const values = form.getFieldsValue()
                  total = formatQuotations(orders, values).reduce(
                    (prev, cur) => {
                      return (prev += (cur.num || 0) * cur.price)
                    },
                    0
                  )
                }

                const {
                  carTotal,
                  poTotal,
                  taxTotal,
                  totalAmount
                } = generateTotalObj(total, totalObj.numTotal)

                calculatedTotal?.(totalAmount)

                return (
                  <>
                    车辆汇总价格: {normalizePrice(carTotal)}
                    <Divider type='vertical' />
                    PO价格: {normalizePrice(poTotal)}{' '}
                    <Divider type='vertical' />
                    税费: {normalizePrice(taxTotal)} <Divider type='vertical' />
                    合计: {normalizePrice(totalAmount)}{' '}
                  </>
                )
              }}
            </Form.Item>
          </Col>
          <Col flex='150px'>
            <Space>
              {/* {meetingDetail?.status === UpMeetingStatus.UMeetingInProgress &&
                meetingDetail.subStatus !== 'U_AWAIT_COMFIRM_COMPLETE' &&
                (userStore.isAdmin || userStore.isCompanyMeetingManager) && (
                  <Space direction='horizontal'>
                    <UploadButton
                      bucket='bms-up'
                      dir={`/admin/po-bills/${meetingDetail.id}`}
                      onUploaded={uploadPOHandler}
                      buttonText='上传PO单'
                    />
                  </Space>
                )} */}
              {meetingDetail?.bmsMarkedPaper && (
                <Button
                  type='link'
                  target='__blank'
                  href={meetingDetail.bmsMarkedPaper}
                >
                  <DownloadOutlined />
                  下载报价单
                </Button>
              )}
              {meetingDetail?.bmsPOBill && (
                <Button
                  type='link'
                  target='__blank'
                  href={meetingDetail.bmsPOBill}
                >
                  <DownloadOutlined />
                  下载PO单
                </Button>
              )}
            </Space>
          </Col>
        </Row>
        {canQuotation && (
          <Form.Item shouldUpdate={true} noStyle>
            {(form) => (
              <Row gutter={24}>
                <Col span={24} style={{ textAlign: 'right' }}>
                  <Space direction='horizontal'>
                    {selectedRowKeys.length > 0 && (
                      <Button
                        type='link'
                        loading={fetching}
                        onClick={() => autoQuote(form)}
                      >
                        自动报价
                      </Button>
                    )}
                    <Button
                      onClick={() => quotationForm.submit()}
                      type='primary'
                      loading={fetching}
                    >
                      保存报价
                    </Button>
                    <DownloadQuotationBill
                      meeting={meetingDetail}
                      formValues={form.getFieldsValue()}
                    />
                    <UploadButton
                      bucket='bms-up'
                      dir={`/admin/marked-quotation-bills/${meetingDetail?.id}`}
                      buttonText='上传盖章报价单'
                      onUploaded={uploadSignPaperHandler}
                    />
                  </Space>
                </Col>
              </Row>
            )}
          </Form.Item>
        )}
      </Form>
    </>
  )
}

export default OrdersTable
