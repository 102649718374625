import React, { FC, useEffect } from 'react'
import { Card } from 'antd'
import {
  Map,
  Polyline,
  ArrayLngLat,
  Marker,
  MarkerProps,
  PolylineProps
} from 'react-amap'
import useAxios from 'axios-hooks'

import { FETCH_CAR_ORDER_TRACE } from '../../apis/car'
import StartMarkerPng from '../../assets/start-marker.png'
import EndMarkerPng from '../../assets/end-marker.png'

interface Point {
  lat: string
  lng: string
}
interface TracePoint {
  point: Point
  time: string
}
const amapkey = process.env.AMAP_KEY
const MapCard: FC<{ driveId: string }> = ({ driveId }) => {
  const [{ data }, fetchPoints] = useAxios(
    {
      url: FETCH_CAR_ORDER_TRACE,
      method: 'POST',
      headers: {
        token: window.localStorage.getItem(process.env.TOKEN_KEY || '')
      }
    },
    {
      manual: true
    }
  )
  const points = data?.result || []

  const paths: ArrayLngLat[] = points.map((point: TracePoint) => [
    Number(point.point.lng),
    Number(point.point.lat)
  ])
  useEffect(() => {
    fetchPoints({ data: { id: driveId } })
  }, [driveId])
  // 如果小于两个点  就啥也不反回了
  if (paths.length <= 2) {
    return null
  }
  // 标记起始点
  const startMarker: MarkerProps = {
    position: {
      longitude: paths[0][0],
      latitude: paths[0][1]
    },
    icon: StartMarkerPng
  }
  const endMarker: MarkerProps = {
    position: {
      longitude: paths[paths.length - 1][0],
      latitude: paths[paths.length - 1][1]
    },
    icon: EndMarkerPng
  }
  const polyLineStyle: object = {
    showDir: true, //是否绘制箭头
    isOutline: true,
    strokeColor: '#3777FF', // 线填充色
    strokeWeight: 6, // 折线宽度
    borderWeight: 5, // 边线宽度
    outlineColor: '#FFF', // 边线颜色
    lineJoin: 'round',
    lineCap: 'round' // 线端头方式
  }
  return (
    <Card title='轨迹'>
      <section style={{ height: '500px' }}>
        <Map amapkey={amapkey} zoom={16} center={paths[0]}>
          <Polyline path={paths} style={polyLineStyle} />
          <Marker position={startMarker.position}>
            <img src={startMarker.icon} />
          </Marker>
          <Marker position={endMarker.position}>
            <img src={endMarker.icon} />
          </Marker>
        </Map>
      </section>
    </Card>
  )
}

export default MapCard
