/* eslint-disable @typescript-eslint/no-empty-function */
import React, { PropsWithChildren } from 'react'
import { createContext, useContext } from 'react'
import { useLocalStore } from 'mobx-react'
import { CurrentUserQuery } from '../graphql'
import {
  COMPANY_MEETING_MANAGER,
  COMPANY_MEETING_BUYER,
  SYT_MEETING_MANAGER,
  SUPERADMIN
} from '../constant/user'

export interface UserProfile {
  accountId?: string | null
  companyId?: string | null
  name?: string | null
}

export interface UserContext {
  profile: CurrentUserQuery['user'] | null
  isLoggedIn: boolean
  token: string | null
  login: (token: string, profile?: CurrentUserQuery['user']) => void
  logout: () => void
  setUser: (profile: CurrentUserQuery['user']) => void
  isCompanyBuyer: boolean
  isCompanyMeetingManager: boolean
  isAdmin: boolean
  isSYTMeetingManager: boolean
}

const userContext = createContext<MaybeNull<UserContext>>(null)

export const createUserStore = (): UserContext => {
  const storedToken = localStorage.getItem('bms-login-token')
  return {
    profile: null,
    isLoggedIn: !!storedToken,
    token: storedToken,
    setUser(profile): void {
      this.profile = profile
      profile?.roles.forEach((role) => {
        if (role.type === COMPANY_MEETING_MANAGER) {
          this.isCompanyMeetingManager = true
        } else if (role.type === COMPANY_MEETING_BUYER) {
          this.isCompanyBuyer = true
        } else if (role.type === SYT_MEETING_MANAGER) {
          this.isSYTMeetingManager = true
        } else if (role.type === SUPERADMIN) {
          this.isAdmin = true
        }
      })
    },
    login(token, profile): void {
      if (!profile) return
      this.token = token
      localStorage.setItem('bms-login-token', token)
      this.setUser(profile)
      this.isLoggedIn = true
    },
    logout(): void {
      localStorage.removeItem('bms-login-token')
      this.isLoggedIn = false
      this.isCompanyBuyer = false
      this.isAdmin = false
      this.isCompanyMeetingManager = false
      this.isSYTMeetingManager = false
    },
    isCompanyBuyer: false,
    isCompanyMeetingManager: false,
    isAdmin: false,
    isSYTMeetingManager: false
  }
}

export const StoreProvider = ({
  children
}: PropsWithChildren<{}>): JSX.Element => {
  const store = useLocalStore(createUserStore)
  return <userContext.Provider value={store}>{children}</userContext.Provider>
}

export const useUserStore = (): UserContext => {
  const store = useContext(userContext)
  if (!store) {
    throw new Error('useUserStore must be used within a StoreProvider.')
  }
  return store
}
