import React, { FC, useState, useEffect } from 'react'
import { BackTop } from 'antd'
import ProLayout, {
  PageHeaderWrapper,
  PageLoading
} from '@ant-design/pro-layout'
import { Switch, Route, useLocation } from 'react-router-dom'

import { useCurrentUserQuery } from './graphql'
import { useUserStore } from './hooks/user'
import Login from './pages/Login'
import SideBarLink from './components/SideBarLink'
import { defaultRoutes } from './defaultRoutes'
import { observer } from 'mobx-react'
import UserPanel from './components/UserPanel'

const App: FC = () => {
  const [collapsed, setCollapsed] = useState(false)
  const location = useLocation()
  const [{ data, fetching }] = useCurrentUserQuery()
  const userStore = useUserStore()

  useEffect(() => {
    if (data?.user) {
      userStore.setUser(data.user)
    }
  }, [data?.user])

  if (fetching) return <PageLoading />
  if (!userStore.isLoggedIn) return <Login />

  return (
    <ProLayout
      fixedHeader
      fixSiderbar
      navTheme='dark'
      siderWidth={180}
      route={defaultRoutes}
      collapsed={collapsed}
      onCollapse={setCollapsed}
      location={{ pathname: location.pathname }}
      menuItemRender={(menuItemProps): React.ReactNode => {
        return <SideBarLink {...menuItemProps} collapsed={collapsed} />
      }}
      menuHeaderRender={(_, __, props): React.ReactNode => (
        <h1 style={{ color: '#fff', marginTop: 20 }}>
          BMS {props?.collapsed ? '' : '管理后台'}
        </h1>
      )}
      rightContentRender={(): React.ReactNode => <UserPanel />}
    >
      <Switch>
        {defaultRoutes.routes?.map((route) => {
          return (
            <Route exact path={route.path} key={route.name}>
              <PageHeaderWrapper title={route.component?.pageTitle}>
                {route.component ? <route.component /> : null}
              </PageHeaderWrapper>
            </Route>
          )
        })}
      </Switch>
    </ProLayout>
  )
}

export default observer(App)
