import React, { FC } from 'react'
import { Skeleton, Descriptions, Card } from 'antd'
import dayjs from 'dayjs'

import { MeetingBaseInfoFragment } from '../../graphql'
const MeetingCard: FC<{
  fetching: boolean
  data: MaybeNull<{ __typename?: 'MeetingInfo' } & MeetingBaseInfoFragment>
}> = ({ fetching, data }) => {
  if (fetching) {
    return <Skeleton active />
  }
  return (
    <Card title='会议信息'>
      <Descriptions title=''>
        <Descriptions.Item label='会议代码'>{data?.code}</Descriptions.Item>
        <Descriptions.Item label='会议开始时间'>
          {dayjs(data?.startTime).format('YYYY-MM-DD HH:mm')}
        </Descriptions.Item>
        <Descriptions.Item label='预计外部参会人数（含讲者）'>
          {data?.outsideAttenderNum}
        </Descriptions.Item>
        <Descriptions.Item label='会议名称'>{data?.name}</Descriptions.Item>
        <Descriptions.Item label='会议城市'>
          {data?.city?.name}
        </Descriptions.Item>
        <Descriptions.Item label='会议结束时间'>
          {dayjs(data?.endTime).format('YYYY-MM-DD HH:mm')}
        </Descriptions.Item>
        <Descriptions.Item label='预计内部参会人数'>
          {data?.insideAttenderNum}
        </Descriptions.Item>
        <Descriptions.Item label='会议部门'>
          {data?.department}
        </Descriptions.Item>
        <Descriptions.Item label='会议类型'>{data?.type}</Descriptions.Item>
        <Descriptions.Item label='会议酒店'>
          {data?.bmsMeetingHotel?.name || data?.hotel}
        </Descriptions.Item>
        <Descriptions.Item label='会议联系人'>
          {data?.ownerName}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  )
}

export default MeetingCard
