import { MeetingDetailQuery } from '../../graphql'

export const formatData = (detail: MeetingDetailQuery['meetingDetail']) => {
  const typeAndSuplier = {
    offlineteam: {
      //线下车队五座
      comfortable: 0,
      //线下车队七座
      business: 0
    },
    online: {
      //预约车五座
      comfortable: 0,
      //预约车七座
      business: 0
    }
  }
// 只统计 已完成的用车订单
  const abledCarOrders =
    detail?.carOrders?.filter((order: any) =>
      ['PF_TRAVEL_FINISHED'].includes(order.status)
    ) || []
    
  abledCarOrders.map((order: any) => {
    const supplier = order.supplier === 'offlineteam' ? 'offlineteam' : 'online'
    // 除了offlineteam以外的都算是预约车
    order.schedules?.map((schedule: any) => {
      schedule.drives?.map((drive: any) => {
        if (drive.model === 'comfortable') {
          typeAndSuplier[supplier].comfortable++
        } else if (drive.model === 'business') {
          typeAndSuplier[supplier].business++
        }
      })
    })
  })
  // const typeAndBudget = orders
  // 固定顺序
  const typeAndBudget: { [key: string]: any } = [
    {
      key: '1',
      type: '网约车',
      now: {
        count: 0,
        cost: 0
      },
      reserve: {
        count: 0,
        cost: 0
      },
      pickup: {
        count: 0,
        cost: 0
      },
      dropoff: {
        count: 0,
        cost: 0
      },
      rent: {
        count: 0,
        cost: 0
      }
    },
    {
      key: '2',
      type: '线下车队',
      now: {
        count: 0,
        cost: 0
      },
      reserve: {
        count: 0,
        cost: 0
      },
      pickup: {
        count: 0,
        cost: 0
      },
      dropoff: {
        count: 0,
        cost: 0
      },
      rent: {
        count: 0,
        cost: 0
      }
    }
  ]
  abledCarOrders.map((order: any) => {
    const listIndex = order.supplier === 'offlineteam' ? 1 : 0
    // 除了offlineteam以外的都算是预约车
    order.schedules?.map((schedule: any) => {
      schedule.drives?.map((drive: any) => {
        if (order.serviceType === 'now') {
          typeAndBudget[listIndex].now.count++
          typeAndBudget[listIndex].now.cost += drive.totalCost || 0
        } else if (order.serviceType === 'reserve') {
          typeAndBudget[listIndex].reserve.count++
          typeAndBudget[listIndex].reserve.cost += drive.totalCost || 0
        } else if (order.serviceType === 'pick_up') {
          typeAndBudget[listIndex].pickup.count++
          typeAndBudget[listIndex].pickup.cost += drive.totalCost || 0
        } else if (order.serviceType === 'drop_off') {
          typeAndBudget[listIndex].dropoff.count++
          typeAndBudget[listIndex].dropoff.cost += drive.totalCost || 0
        } else if (order.serviceType === 'rent') {
          typeAndBudget[listIndex].rent.count++
          typeAndBudget[listIndex].rent.cost += drive.totalCost || 0
        }
      })
    })
  })
  const calcAveByType = (type: string) => {
    return (
      (typeAndBudget[0][type].cost + typeAndBudget[1][type].cost) /
        (typeAndBudget[0][type].count + typeAndBudget[1][type].count) || 0
    ).toFixed(2)
  }
  const aveCost = {
    key: '3',
    type: '平均费用（￥）',
    now: calcAveByType('now'),
    rent: calcAveByType('rent'),
    dropoff: calcAveByType('dropoff'),
    pickup: calcAveByType('pickup'),
    reserve: calcAveByType('reserve')
  }
  // 五座 舒适型
  // 七座 商务型
  const formatBudget = typeAndBudget.map((item: any) => {
    return {
      ...item,
      now: item.now.count,
      rent: item.rent.count,
      dropoff: item.dropoff.count,
      pickup: item.pickup.count,
      reserve: item.reserve.count
    }
  })
  return {
    typeAndSuplier,
    typeAndBudget: [...formatBudget, aveCost]
  }
}
