import React, { FC } from 'react'
import ExcelParseButton from './ExcelParse'
import {
  useAddAttendersMutation,
  MeetingDetailQuery,
  UpMeetingStatus
} from '../graphql'
import { message, Button, Modal } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'

const UploadAttenderXlsx: FC<{
  meeting: MeetingDetailQuery['meetingDetail']
}> = ({ meeting }) => {
  const [{ data, error, fetching }, addAttenders] = useAddAttendersMutation()

  const onExcelParsed = async (data: any[]) => {
    const doctors = data
      .filter((d: any) => d[0] && d[1])
      .splice(1, data.length - 1)
      .map((doctor: any) => ({
        name: doctor[0],
        title: doctor[1],
        hospital: doctor[2],
        department: doctor[3],
        mobile: doctor[10],
        inviterMobile: doctor[11],
        inviterName: doctor[12]
      }))

    const result = await addAttenders({
      attenders: doctors,
      meetingId: meeting?.id!
    })

    if (result.error) {
      return message.error(result.error.message)
    }

    if (result.data?.success) {
      return message.success('导入成功')
    }
    message.error('导入失败')
  }

  if (!meeting?.bmsPOBill) {
    return (
      <Button
        onClick={() => Modal.info({ content: '请先上传PO单' })}
        type='primary'
      >
        <UploadOutlined />
        上传参会者信息
      </Button>
    )
  }

  if (meeting.status !== UpMeetingStatus.UMeetingInProgress) {
    return (
      <Button
        type='primary'
        onClick={() =>
          Modal.info({
            content: '会议未进入执行中，不支持修改，如有问题请联系EMCOE团队'
          })
        }
      >
        <UploadOutlined />
        上传参会者信息
      </Button>
    )
  }
  return (
    <ExcelParseButton
      loading={fetching}
      onParsed={onExcelParsed}
      sheetName='医生行程需求表TTT'
      buttonText='上传参会者信息'
    />
  )
}

export default UploadAttenderXlsx
