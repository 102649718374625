export const COMPANY_MEETING_MANAGER =
  process.env.COMPANY_MEETING_MANAGER ||
  'PRIVATE-103200513165800001-202006111512361'

export const COMPANY_MEETING_BUYER =
  process.env.COMPANY_MEETING_BUYER ||
  'PRIVATE-103200513165800001-202006111512220'

export const SYT_MEETING_MANAGER =
  process.env.SYT_MEETING_MANAGER ||
  'PRIVATE-103200513165800001-202006111512478'

export const SUPERADMIN = 'group_admin'

export enum ROLE_NAME {
  companyMeetingManager = 'PRIVATE-103200513165800001-202006111512361',
  companyMeetingBuyer = 'PRIVATE-103200513165800001-202006111512220',
  sytMeetingManager = 'PRIVATE-103200513165800001-202006111512478',
  superAdmin = 'group_admin'
}
