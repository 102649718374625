import React, { FC, PropsWithChildren } from 'react'
import { Descriptions, Skeleton, Space, Popover, Card, Table } from 'antd'
import dayjs from 'dayjs'
import { QuestionCircleOutlined } from '@ant-design/icons'
import {
  CAR_TYPE,
  CAR_SERVICE_TYPE,
  CAR_SUPPLIER,
  CAR_FROM
} from '../../constant/car'
import './index.less'
const formatErrorStatus = ({ status }: { status: string }): string => {
  if (['0', 'N'].includes(status)) {
    return '否'
  } else if (['Y'].includes(status)) {
    return '是'
  } else {
    return '-'
  }
}
type Point = {
  landMark?: string
  address?: string
}
type Cost = {
  duration?: string
  kilometers?: string
  totalCost?: number
}
type Positon = {
  address?: string
  landMark?: string
}
type RealPositon = {
  destination?: Positon
  DESTINATION?: Positon
  origin?: Positon
  ORIGIN?: Positon
}
type Drive = {
  dispatchedTime?: string
  preCostDetail?: Cost
  costDetail?: Cost
  realPosition?: RealPositon
  predictCost?: number
  beginChargeTime?: string
}
type Passenger = {
  name?: string
  phone?: string
}
type RiskItem = {
  type?: string
  display?: string
  status?: string
  value?: string
}
type RiskInfo = {
  riskItems: [RiskItem]
}
interface ScheduleInfo {
  model?:
    | 'economic'
    | 'comfortable'
    | 'business'
    | 'luxurious'
    | 'minibus'
    | 'midibus'
    | 'bigbus'
  origin?: Point
  departureTime?: string
  drives?: [Drive]
  destination?: Point
  passenger: Passenger
  risks: RiskInfo
}
type OrderDetail = {
  userId?: string
  userName?: string
  userPhone?: string
  supplier?: 'didi' | 'shenzhou' | 'offlineteam'
  serviceType?: 'now' | 'reserve' | 'rent' | 'pick_up' | 'drop_off'
}

// 行程异常DescriptionsItem
const RiskDescriptionsItem = ({
  riskItems,
  label,
  type
}: {
  riskItems: [RiskItem]
  label: React.ReactNode
  type: string
}) => {
  const value = riskItems?.find((item: RiskItem) => item.type === type)?.value
  const isError = value === 'Y' || value === '0'
  const errorClassName = isError ? 'error' : ''

  return (
    <Descriptions.Item label={label} className={errorClassName}>
      {formatErrorStatus({ status: value || '' })}
    </Descriptions.Item>
  )
}

// 乘车人table
const PassagerTable: FC<{ passengers: Passenger[]; riskItems: RiskItem[] }> = ({
  passengers,
  riskItems
}) => {
  // 目前行程异常数量和乘车人异常判断条件一致
  const scheduleRiskValue = riskItems?.find(
    (item) => item.type === 'PASSENGER_ABNORMAL'
  )?.value
  const passagerRiskValue = formatErrorStatus({
    status: scheduleRiskValue || ''
  })
  const scheduleRiskClassName =
    formatErrorStatus({
      status: scheduleRiskValue || ''
    }) === '是'
      ? 'error'
      : ''
  const passagerRiskClassName = passagerRiskValue === '是' ? 'error' : ''
  const columns = [
    {
      title: '乘车人姓名',
      dataIndex: 'name',
      key: 'name',
      render: (v: any) => v || '-'
    },
    {
      title: '乘车人手机号',
      dataIndex: 'phone',
      key: 'phone',
      render: (v: any) => v || '-'
    },
    {
      title: '同一时段其它行程',
      dataIndex: 'other',
      key: 'other',
      className: scheduleRiskClassName,
      render: () => scheduleRiskValue || '-'
    },
    {
      title: (
        <TipLabel
          label='乘车人异常'
          content='行程中任一乘车人，同一时段有其它未取消订单'
        />
      ),
      dataIndex: 'error',
      key: 'error',
      className: passagerRiskClassName,
      render: () => passagerRiskValue
    }
  ]
  return (
    <Table
      className='passenger-table'
      style={{ width: '40%' }}
      bordered
      rowKey='phone'
      dataSource={passengers}
      columns={columns}
      pagination={false}
    />
  )
}

// 自定义DescriptionsLayout
const DescriptionsLayout = ({
  children
}: PropsWithChildren<{}>): JSX.Element => {
  // 获取子元素个数
  const childrenCount = React.Children.count(children)
  // 子元素个数 百分比，最大为100
  const widthPercent = Math.min(childrenCount, 10) * 10 + '%'
  return (
    <Descriptions
      title=''
      layout='vertical'
      bordered
      column={10}
      style={{ width: widthPercent }}
    >
      {children}
    </Descriptions>
  )
}
// 时长异常label
const TipLabel = ({
  label,
  content
}: {
  label: string
  content: string
}): JSX.Element => (
  <>
    {label}
    <Popover content={<div>{content}</div>}>
      <QuestionCircleOutlined style={{ color: 'rgba(204,204,204,1)' }} />
    </Popover>
  </>
)

const ScheduleCard: FC<{
  fetching: boolean
  data: [ScheduleInfo]
  orderDetail: OrderDetail
}> = ({ fetching, data, orderDetail }) => {
  if (fetching) {
    return <Skeleton active />
  }
  // 默认取第一个行程的信息就行
  const scheduleDetail = data[0]
  const riskItems = scheduleDetail?.risks?.riskItems
  return (
    <Card
      title={
        <>
          行程信息
          <span className='tip'>
            下单人：{orderDetail?.userName}（{orderDetail?.userPhone}）
          </span>
        </>
      }
    >
      <Space direction='vertical' size={10} className='schedule-container'>
        <DescriptionsLayout>
          <Descriptions.Item label='用车渠道'>
            {orderDetail?.supplier ? CAR_FROM[orderDetail?.supplier] : '-'}
          </Descriptions.Item>
          <Descriptions.Item label='供应商'>
            {orderDetail?.supplier ? CAR_SUPPLIER[orderDetail?.supplier] : '-'}
          </Descriptions.Item>
          <Descriptions.Item label='服务类型'>
            {orderDetail?.serviceType
              ? CAR_SERVICE_TYPE[orderDetail?.serviceType]
              : '-'}
          </Descriptions.Item>
          <Descriptions.Item label='车型'>
            {scheduleDetail?.model ? CAR_TYPE[scheduleDetail?.model] : '-'}
          </Descriptions.Item>
        </DescriptionsLayout>
        <DescriptionsLayout>
          <Descriptions.Item label='预约用车时间'>
            {scheduleDetail.departureTime
              ? dayjs(scheduleDetail.departureTime).format('YYYY-MM-DD HH:mm')
              : '-'}
          </Descriptions.Item>
          <Descriptions.Item label='行程开始时间'>
            {scheduleDetail?.drives?.[0]?.beginChargeTime
              ? dayjs(scheduleDetail?.drives?.[0]?.beginChargeTime).format(
                  'YYYY-MM-DD HH:mm'
                )
              : '-'}
          </Descriptions.Item>
          <Descriptions.Item label='预计时长（min）'>
            {scheduleDetail?.drives?.[0]?.preCostDetail?.duration || '-'}
          </Descriptions.Item>
          <Descriptions.Item label='实际时长（min）'>
            {scheduleDetail?.drives?.[0]?.costDetail?.duration || '-'}
          </Descriptions.Item>
          {RiskDescriptionsItem({
            label: (
              <TipLabel
                label='用车时间异常'
                content='预约用车时间，行程开始时间间隔 > 30分钟'
              />
            ),
            type: 'TIME_ABNORMAL',
            riskItems: riskItems
          })}
          {RiskDescriptionsItem({
            label: (
              <TipLabel
                label='用车时长异常'
                content='预约用车时长，实际时长相差 > 30分钟'
              />
            ),
            type: 'DURATION_ABNORMAL',
            riskItems: riskItems
          })}
        </DescriptionsLayout>
        <DescriptionsLayout>
          <Descriptions.Item label='预约上车地点'>
            {scheduleDetail?.origin?.landMark ||
              scheduleDetail?.origin?.address ||
              '-'}
          </Descriptions.Item>
          <Descriptions.Item label='行程开始地点'>
            {scheduleDetail?.drives?.[0]?.realPosition?.ORIGIN?.landMark ||
              scheduleDetail?.drives?.[0]?.realPosition?.ORIGIN?.address ||
              '-'}
          </Descriptions.Item>
          <Descriptions.Item label='预约下车地点'>
            {scheduleDetail?.destination?.landMark ||
              scheduleDetail?.destination?.address ||
              '-'}
          </Descriptions.Item>
          <Descriptions.Item label='实际下车地点'>
            {scheduleDetail?.drives?.[0]?.realPosition?.DESTINATION?.landMark ||
              scheduleDetail?.drives?.[0]?.realPosition?.DESTINATION?.address ||
              '-'}
          </Descriptions.Item>
          <Descriptions.Item label='预计公里数'>
            {scheduleDetail?.drives?.[0]?.preCostDetail?.kilometers || '-'}
          </Descriptions.Item>
          <Descriptions.Item label='实际公里数'>
            {scheduleDetail?.drives?.[0]?.costDetail?.kilometers || '-'}
          </Descriptions.Item>

          {RiskDescriptionsItem({
            label: (
              <TipLabel
                label='上车地点异常'
                content='预计上车点与实际上车点直线距离大于3公里'
              />
            ),
            type: 'GET_IN_ABNORMAL',
            riskItems: riskItems
          })}

          {RiskDescriptionsItem({
            label: (
              <TipLabel
                label='下车地点异常'
                content='预计下车点与实际下车点直线距离大于3公里'
              />
            ),
            type: 'GET_OFF_ABNORMAL',
            riskItems: riskItems
          })}

          {RiskDescriptionsItem({
            label: (
              <TipLabel
                label='用车距离异常'
                content='实际公里数/预计公里数 > 120% '
              />
            ),
            type: 'DISTANCE_ABNORMAL',
            riskItems: riskItems
          })}
        </DescriptionsLayout>
        <PassagerTable
          passengers={data
            .map((item: ScheduleInfo) => item.passenger)
            .filter((item) => !!item)}
          riskItems={riskItems}
        />
        <DescriptionsLayout>
          <Descriptions.Item label='预计价格'>
            {scheduleDetail?.drives?.[0]?.predictCost || '-'}
          </Descriptions.Item>
          <Descriptions.Item label='实际价格'>
            {scheduleDetail?.drives?.[0]?.costDetail?.totalCost || '-'}
          </Descriptions.Item>

          {RiskDescriptionsItem({
            label: (
              <TipLabel label='费用异常' content='实际价格 / 预计价格 > 120%' />
            ),
            type: 'FEE_ABNORMAL',
            riskItems: riskItems
          })}
        </DescriptionsLayout>
      </Space>
    </Card>
  )
}

export default ScheduleCard
