import React, { FC } from 'react'
import useAxios from 'axios-hooks'
import { Button } from 'antd'
import { omit } from 'lodash'
import { MeetingDetailQuery } from '../graphql'
import dayjs from 'dayjs'
import { DownloadOutlined } from '@ant-design/icons'
import {
  CAR_SUPPLIER,
  CAR_SERVICE_TYPE,
  CAR_SERVICE_STATUS
} from '../constant/car'

const formatDate = (date: any, format: string) => {
  if (!date) return 'N/A'
  return dayjs(date).format(format)
}

const DownloadCostBreakDown: FC<{
  meeting: MeetingDetailQuery['meetingDetail']
  carOrders: any
}> = ({ meeting, carOrders }) => {
  const schedules = (carOrders as any[]).reduce<any[]>(
    (s: any[], c: { schedules: any[] }) =>
      s.concat({ ...c.schedules[0], orderInfo: omit(c, 'schedules') }),
    []
  )
  const [{ loading }, generateExcel] = useAxios(
    {
      baseURL: process.env.FAAS_SERVICE,
      url: 'bms-excel-generate',
      method: 'POST'
    },
    {
      manual: true
    }
  )

  const generate = async () => {
    const { data } = await generateExcel({
      data: {
        templateName: 'schedule-detail',
        data: [
          meeting?.name,
          meeting?.code,
          '商宴通',
          '付璀 （18666019817）',
          meeting?.city?.name,
          formatDate(meeting?.startTime, 'YYYY-MM-DD'),
          formatDate(meeting?.endTime, 'YYYY-MM-DD'),
          schedules.map((schedule, index) => {
            const isCanceled = [
              'PF_TRAVEL_CANCELED',
              'PF_TRAVEL_CANCELED_WITH_COST'
            ].includes(schedule.orderInfo?.status)

            return [
              index + 1, //'序列号',
              'N/A',
              schedule.passenger?.name, //'乘车人姓名',
              schedule.origin?.city, //'出发城市',
              formatDate(schedule.departureTime, 'YYYY-MM-DD'), // '出发日期(YYYY-MM-DD)',
              CAR_SERVICE_TYPE[
                schedule.orderInfo?.serviceType as keyof typeof CAR_SERVICE_TYPE
              ], //'服务类型',
              formatDate(schedule.departureTime, 'HH:mm'), //'预计上车时间(HH:MM)',
              isCanceled
                ? 'N/A'
                : formatDate(schedule.drives?.[0]?.beginChargeTime, 'HH:mm'), //'实际上车时间(HH:MM)',
              schedule.origin?.landMark || schedule.origin?.address, //'预计上车地点',
              isCanceled
                ? 'N/A'
                : schedule.drives?.[0]?.realPosition?.ORIGIN?.landMark ||
                  schedule.drives?.[0]?.realPosition?.ORIGIN?.address, //'实际上车地点',
              isCanceled || !schedule.drives?.[0]?.preCostDetail?.duration
                ? 'N/A'
                : schedule.departureTime
                ? dayjs(schedule.departureTime)
                    .add(
                      schedule.drives?.[0]?.preCostDetail?.duration,
                      'minute'
                    )
                    .format('HH:mm')
                : 'N/A', //'预计下车时间(HH:MM)',

              isCanceled
                ? 'N/A'
                : formatDate(schedule.drives?.[0]?.finishedTime, 'HH:mm'), //'实际下车时间(HH:MM)',
              schedule.destination?.landMark || schedule.destination?.address, //'预计下车地点',
              isCanceled
                ? 'N/A'
                : schedule.drives?.[0]?.realPosition?.DESTINATION?.landMark ||
                  schedule.drives?.[0]?.realPosition?.DESTINATION?.address, //'实际下车地点',

              schedule.drives?.[0]?.preCostDetail?.kilometers || 'N/A', //'预估公里数(km)',
              isCanceled
                ? 'N/A'
                : schedule.drives?.[0]?.costDetail?.kilometers || 'N/A', //'实际公里数(km)',
              Math.max(
                0,
                (schedule.drives?.[0]?.costDetail?.kilometers || 0) -
                  (schedule.drives?.[0]?.preCostDetail?.kilometers || 0)
              ).toFixed(2), //'超公里数，若有(km)',
              schedule.drives?.[0]?.preCostDetail?.duration || 'N/A', //预估用车时长(HH:MM)',
              isCanceled
                ? 'N/A'
                : schedule.drives?.[0]?.costDetail?.duration || 'N/A', //'实际用车时长(HH:MM)',
              isCanceled
                ? 0
                : schedule.drives?.[0]?.costDetail?.totalCost || 'N/A', //'实际用车费用(CNY)',
              isCanceled ? schedule.orderInfo?.totalCost || 0 : 'N/A', // '取消费用（元）',
              schedule.orderInfo?.tips || 'N/A', //'备注',
              isCanceled ? 'N/A' : schedule.drives?.[0]?.carType, //'车型',
              schedule.orderInfo?.totalCost, //'总费用(CNY)',
              meeting?.code, //'会议代码',
              '商宴通' //'会务供应商名称'
            ]
          })
        ]
      }
    })
    window.open(data)
  }

  return (
    <Button loading={loading} onClick={generate}>
      <DownloadOutlined />
      下载CostBreakdown
    </Button>
  )
}

export default DownloadCostBreakDown
