import React, { FC } from 'react'
import { useCarScheduleComplianceRisksQuery } from '../graphql'
import { Loading3QuartersOutlined } from '@ant-design/icons'

const ScheduleRiskItem: FC<{
  scheduleId: string | number | undefined
  type: string | string[]
  risks?: any
}> = ({ scheduleId, type, risks }) => {
  if (!scheduleId) return <span>-</span>
  const [{ fetching, data }] = useCarScheduleComplianceRisksQuery({
    pause: !!risks,
    variables: {
      scheduleId:
        typeof scheduleId === 'string' ? scheduleId : scheduleId.toString()
    }
  })
  if (fetching) {
    return <Loading3QuartersOutlined />
  }
  // 同时支持传入单个type和type数组
  const types = Array.isArray(type) ? type : [type]

  const riskItems =
    (
      risks || (data?.risks as { [key: string]: any } | undefined)
    )?.riskItems?.filter((r: any) => types.includes(r.type)) || []
  let errorStatus = '-'
  // 复杂的显示判断
  for (const item of riskItems) {
    if (!['0', 'N', ''].includes(item.value)) {
      // 只要有一个不为['0', 'N', ''] 则判断为是异常
      errorStatus = '是'
      break
    } else if (['0', 'N'].includes(item.value)) {
      // 为['0', 'N'] 则判断为否异常
      errorStatus = '否'
    }
  }
  return <span>{errorStatus}</span>
}

export default ScheduleRiskItem
