import React from 'react'
import { MenuDataItem } from '@ant-design/pro-layout'
import { FC } from 'react'
import { Link } from 'react-router-dom'

const SideBarLink: FC<
  MenuDataItem & {
    isUrl: boolean
    collapsed: boolean
  }
> = (props) => {
  const Icon = props.icon as FC
  return (
    <Link to={props.path || '/'}>
      <Icon />
      {props.collapsed ? null : props.name}
    </Link>
  )
}

export default SideBarLink
