import React, { FC, useCallback } from 'react'
import ExcelParseButton, { ExcelParseButtonProps } from './ExcelParse'
import {
  useOrderBatchSyncMutation,
  MeetingDetailQuery,
  UpMeetingBudgetType,
  OrderBatchSyncMutation
} from '../graphql'
import { message } from 'antd'

export type UploadTravelsFromExcelProps = {
  onResult: (
    id?: OrderBatchSyncMutation['meetingOrderBatchSync'] | null
  ) => void
  meeting: MeetingDetailQuery['meetingDetail']
}

const UploadTravelsFromExcel: FC<UploadTravelsFromExcelProps> = ({
  meeting,
  onResult
}) => {
  const [{ fetching }, batchSyncOrders] = useOrderBatchSyncMutation()

  const onExcelParsed = useCallback<ExcelParseButtonProps['onParsed']>(
    async (data: any[]) => {
      console.log(data)
      if (!data) return

      const travelStartIndex = data.findIndex((item) => item[0] === '行程')
      const orders = data
        .splice(travelStartIndex + 3, data.length - travelStartIndex + 1)
        .filter((item) => item[0])
        .map((item) => ({
          num: item[2],
          carType: item[1],
          type: item[0],
          unit: item[3],
          note: item[4]
        }))

      const res = await batchSyncOrders({
        meetingId: meeting?.id!,
        budgetId:
          meeting?.budgets.items.find(
            (budget) =>
              budget.type === UpMeetingBudgetType.ShortDistanceTransportationFee
          )?.id || '',
        orders
      })

      if (res.error) {
        message.error(res.error.graphQLErrors[0].message)
        return
      }

      if (res.data?.meetingOrderBatchSync) {
        message.success('success')
      }

      onResult(res.data?.meetingOrderBatchSync)
    },
    []
  )

  const onExcelParseError: ExcelParseButtonProps['onParseError'] = (e) => {
    message.error(e)
  }

  return (
    <ExcelParseButton
      loading={fetching}
      onParsed={onExcelParsed}
      sheetName='会议信息'
      onParseError={onExcelParseError}
      buttonText='上传用车需求'
    />
  )
}

export default UploadTravelsFromExcel
