import React, { FC, useRef, useEffect } from 'react'
import { Select } from 'antd'
import useAxios from 'axios-hooks'
import throttle from 'lodash.throttle'
import { SelectProps } from 'antd/lib/select'

import { useCityListQuery, useMeetingStatusMapQuery } from '../graphql'

interface Props<T = string> {
  value: string
  valueKey?: 'id' | 'name'
  onChange: SelectProps<T>['onChange']
  style?: React.CSSProperties
}

export const PoiSelector: FC<Partial<
  Props<AmapPOI | undefined> & { city: string }
>> = ({ value = '', city = '', onChange, style }) => {
  const [{ data }, fetchPOIs] = useAxios<AmapPOIResponse>(
    {
      url: 'https://restapi.amap.com/v3/place/text',
      method: 'GET'
    },
    {
      manual: true
    }
  )
  const throttledFetchPOIs = useRef<any>(null)

  useEffect(() => {
    throttledFetchPOIs.current = throttle(
      (value: string) =>
        value &&
        fetchPOIs({
          params: {
            city,
            keywords: value,
            key: process.env.AMAP_KEY
          }
        }),
      1200
    )
  }, [])

  useEffect(() => {
    if (value) {
      fetchPOIs({
        params: {
          city,
          keywords: value,
          key: process.env.AMAP_KEY
        }
      })
    }
  }, [])

  return (
    <Select
      showSearch
      allowClear
      style={style}
      placeholder='Select a person'
      optionFilterProp='children'
      onSearch={(v) => throttledFetchPOIs.current?.(v)}
      value={value}
      onChange={(id, options) => {
        onChange?.(
          data.pois.find((poi) => poi.id === id),
          options
        )
      }}
    >
      {data?.pois.map((poi) => {
        return (
          <Select.Option value={poi.id!} key={poi.id}>
            {poi.name}
            <p style={{ fontSize: 12 }}>{poi.address}</p>
          </Select.Option>
        )
      })}
    </Select>
  )
}

export const CitySelector: FC<Partial<Props>> = ({
  value = '',
  valueKey = 'id',
  onChange,
  style
}) => {
  const [{ data }] = useCityListQuery()
  return (
    <Select
      showSearch
      allowClear
      style={style}
      placeholder='Select a person'
      optionFilterProp='children'
      value={value}
      onChange={onChange}
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {data?.cities.map((city) => {
        return (
          <Select.Option value={city[valueKey]} key={city[valueKey]}>
            {city.name}
          </Select.Option>
        )
      })}
    </Select>
  )
}

export const MeetingStatusSelector: FC<Partial<Props>> = ({
  value = '',
  onChange,
  style
}) => {
  const [{ data }] = useMeetingStatusMapQuery()
  return (
    <Select
      allowClear
      showSearch
      style={style}
      placeholder='Select a person'
      optionFilterProp='children'
      value={value}
      onChange={onChange}
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {data?.statusMap.map((status) => {
        return (
          <Select.Option value={status.value} key={status.value}>
            {status.label}
          </Select.Option>
        )
      })}
    </Select>
  )
}
