import React, { useCallback, useEffect, FC } from 'react'
import { Form, Input, Button, message } from 'antd'
import { FormProps } from 'antd/lib/form'

import { useUserLoginMutation } from '../../graphql'
import { useHistory } from 'react-router-dom'
import { useUserStore } from '../../hooks/user'

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
}
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 }
}

const Login: QCPage = () => {
  const [{ error, fetching }, login] = useUserLoginMutation()
  const userStore = useUserStore()

  const onFinish = useCallback(async (values) => {
    const { data } = await login(values)
    if (data) {
      userStore?.login(data.bmsLogin.token, data.bmsLogin.account)
    }
  }, [])

  const onFinishFailed = useCallback<NonNullable<FormProps['onFinishFailed']>>(
    (error) => {
      message.error({ content: error.errorFields })
    },
    []
  )

  useEffect(() => {
    error && message.error({ content: error?.graphQLErrors[0].message })
  }, [error])

  return (
    <div style={{ width: 400, margin: '80px auto' }}>
      <Form {...layout} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Form.Item label='手机号' name='mobile' required>
          <Input autoComplete='username' />
        </Form.Item>
        <Form.Item label='密码' name='password' required>
          <Input type='password' autoComplete='current-password' />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button loading={fetching} type='primary' htmlType='submit'>
            登录
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

Login.pageTitle = '登录'

export default Login
