import React, { FC, ReactNode } from 'react'
import { useUserStore, UserContext } from '../hooks/user'
import { ROLE_NAME } from '../constant/user'

type RenderChild = (user: UserContext) => ReactNode

const AuthRender = ({
  children
}: {
  roles: (keyof typeof ROLE_NAME)[]
  children: RenderChild | null
}): React.ReactElement | null => {
  const user = useUserStore()

  if (!children) {
    return null
  }

  return <>{children(user)}</>
}

export default AuthRender
