import React, { FC } from 'react'
import { useUserInfoQuery, UserInfoQuery } from '../graphql'
import { Loading3QuartersOutlined } from '@ant-design/icons'

type AccountInfoProps = {
  accountId: string
  field: keyof NonNullable<UserInfoQuery['user']>
}

const AccountInfo: FC<AccountInfoProps> = ({ accountId, field }) => {
  const [{ data, fetching, error }] = useUserInfoQuery({
    variables: {
      accountId
    }
  })

  if (fetching) return <Loading3QuartersOutlined />

  return <span>{data?.user?.[field]}</span>
}

export default AccountInfo
