import React, { FC, useState, useEffect } from 'react'
import { Table, Card } from 'antd'
import { TableProps } from 'antd/lib/table'
import SearchPanel, { SearchPanelProps } from './SearchPanel'

export const useSearchTable = <T extends {}>(
  initialSearchParams: T,
  initialPager?: Pager
) => {
  const [searchParams, updateSearchParams] = useState<T>(initialSearchParams)
  const [pager, updatePager] = useState<Pager>(initialPager || {})

  return {
    searchParams,
    updateSearchParams: (updated: T) => {
      updateSearchParams(updated)
      updatePager((pager) => ({
        ...pager,
        currentPage: 1
      }))
    },
    pager,
    updatePager
  }
}

export interface SearchTableProps extends TableProps<any> {
  table?: ReturnType<typeof useSearchTable>
  pager?: Pager
  searchProps?: SearchPanelProps
  extraPanelContent?: JSX.Element
}

const SearchTable: FC<SearchTableProps> = ({
  table,
  loading,
  pager = { currentPage: 1, pageSize: 15 },
  dataSource,
  columns,
  size,
  bordered,
  rowKey,
  extraPanelContent,
  searchProps
}) => {
  const localTable = table || useSearchTable<any>({ pager })

  useEffect(() => {
    if (!pager) return

    const { currentPage, total } = pager

    localTable.updatePager((currentPager) => ({
      ...currentPager,
      currentPage,
      total
    }))
  }, [JSON.stringify(pager)])

  return (
    <>
      <Card>
        <SearchPanel
          {...searchProps}
          onChange={localTable.updateSearchParams}
        />
        {extraPanelContent && React.cloneElement(extraPanelContent, {})}
      </Card>
      <Table
        bordered={bordered}
        loading={loading}
        rowKey={rowKey}
        pagination={{
          current: localTable.pager?.currentPage,
          pageSize: localTable.pager?.pageSize,
          total: localTable.pager?.total,

          onChange(page) {
            localTable.updatePager((currentPager) => ({
              ...currentPager,
              currentPage: page
            }))
          }
        }}
        dataSource={dataSource}
        size={size}
        columns={columns}
      />
    </>
  )
}

export default SearchTable
