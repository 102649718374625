import { Route as ADLayoutRoute } from '@ant-design/pro-layout/lib/typings'
import MeetingList from './pages/MeetingList'
import MeetingDetail from './pages/MeetingDetail'
import ScheduleDetail from './pages/ScheduleDetail'
import CarUsageAnalytics from './pages/CarUsageAnalytics'
import CarUsageAnalyticsDetail from './pages/CarUsageAnalyticsDetail'
import {
  ExclamationCircleOutlined,
  UnorderedListOutlined,
  BarChartOutlined
} from '@ant-design/icons'
import MeetingCreate from './pages/MeetingCreate'
import ExceptionList from './pages/ExceptionList'
import ExceptionDetail from './pages/ExceptionDetail'

export const defaultRoutes: ADLayoutRoute = {
  routes: [
    {
      path: '/meetingList',
      name: '会议列表',
      key: 'meetingList',
      component: MeetingList,
      icon: UnorderedListOutlined
    },
    {
      path: '/meetingCreate',
      name: '会议创建',
      key: 'meetingCreate',
      component: MeetingCreate,
      hideInMenu: true
    },
    {
      path: '/meetingEdit/:meetingId',
      name: '会议编辑',
      key: 'meetingEdit',
      component: MeetingCreate,
      hideInMenu: true
    },
    {
      path: '/meeting/:meetingId',
      name: '会议详情',
      hideInMenu: true,
      component: MeetingDetail
    },
    {
      path: '/meeting/:meetingId/:action',
      name: '会议报价',
      hideInMenu: true,
      component: MeetingDetail
    },
    {
      path: '/exceptionList',
      name: '异常报告',
      key: 'exceptionList',
      component: ExceptionList,
      icon: ExclamationCircleOutlined
    },
    {
      path: '/carUsageAnalytics',
      name: '用车报表',
      key: 'carUsageAnalytics',
      component: CarUsageAnalytics,
      icon: BarChartOutlined
    },
    {
      path: '/carUsageAnalyticsDetail/:meetingId',
      name: '用车报表详情',
      hideInMenu: true,
      component: CarUsageAnalyticsDetail
    },
    {
      path: '/carOrder/:carOrderId/schedule/:scheduleId',
      name: '行程详情',
      hideInMenu: true,
      component: ScheduleDetail
    },
    {
      path: '/exceptionDetail/:meetingId',
      name: '异常会议详情',
      hideInMenu: true,
      component: ExceptionDetail
    }
  ]
}
