import React, { FC, useState } from 'react'
import { Modal, Form, Input, Button, message } from 'antd'
import { useRetreatQuotationMutation } from '../graphql'

const RetreatConfirm: FC<{ meetingId: string; onSuccess: () => void }> = ({
  meetingId,
  onSuccess
}) => {
  const [form] = Form.useForm()
  const [visible, setVisible] = useState<boolean>(false)

  const [
    { fetching: retreatLoading },
    retreatQuotation
  ] = useRetreatQuotationMutation()

  const onRetreat = async () => {
    try {
      const { reason } = await form.validateFields()
      const { data } = await retreatQuotation({
        meetingId,
        reason
      })

      if (data?.success) {
        message.success('退回成功')
        onSuccess?.()
        setVisible(false)
        return
      }
      message.error('退回失败')
    } catch (e) {
      return
    }
  }

  return (
    <>
      <Button
        loading={retreatLoading}
        type='default'
        onClick={() => {
          setVisible(true)
        }}
      >
        退回重新报价
      </Button>
      <Modal
        visible={visible}
        title='退回重新报价'
        onCancel={() => setVisible(false)}
        onOk={onRetreat}
      >
        <Form form={form} layout='vertical'>
          <Form.Item
            label='退回原因'
            name='reason'
            rules={[
              {
                required: true,
                max: 100
              }
            ]}
            required
          >
            <Input.TextArea
              autoSize
              style={{ minHeight: 200 }}
              placeholder='请填写退回原因,最多100字'
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default RetreatConfirm
