import React, { FC, useState, useEffect, useRef } from 'react'
import { Upload, Button, message } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import useAxios from 'axios-hooks'
import { UploadProps } from 'antd/lib/upload'
import { UploadFile } from 'antd/lib/upload/interface'
import OSS from 'ali-oss'
import { useUserStore } from '../hooks/user'

export interface UploadButtonProps {
  buttonText?: string
  bucket: string
  dir?: string
  onChange?: (fileList: UploadFile<any>[]) => void
  onUploaded?: (url?: string) => void | Promise<void>
}

interface STSServerResponse {
  SecurityToken: string
  AccessKeyId: string
  AccessKeySecret: string
  Expiration: string
}

const useOssClient = (bucket: string) => {
  const { token } = useUserStore()
  const [credentials, setCredentials] = useState<STSServerResponse | null>(null)
  const [, fetchSTS] = useAxios<STSServerResponse>(
    {
      url: process.env.STS_SERVICE,
      headers: {
        token
      }
    },
    { manual: true }
  )

  const ossClient = useRef<OSS | null>(null)

  const createOSSClient = (creds: STSServerResponse) => {
    const client = new OSS({
      region: 'oss-cn-hangzhou',
      bucket,
      accessKeyId: creds.AccessKeyId,
      accessKeySecret: creds.AccessKeySecret,
      stsToken: creds.SecurityToken
    })
    ossClient.current = client
    return client
  }

  return async () => {
    const expired = credentials?.Expiration
      ? new Date().getTime() - new Date(credentials.Expiration).getTime() >= 0
      : true

    if (expired) {
      const { data } = await fetchSTS()
      setCredentials(data)
      return createOSSClient(data)
    }
    return ossClient.current
  }
}

const UploadButton: FC<UploadButtonProps> = ({
  buttonText,
  dir,
  onChange,
  onUploaded,
  bucket
}) => {
  const [running, setRunning] = useState<boolean>(false)
  const getOssClient = useOssClient(bucket)

  const onFileChange: UploadProps['onChange'] = ({ fileList }) => {
    console.log('Aliyun OSS:', fileList)
    if (onChange) {
      onChange([...fileList])
    }
  }

  const onRequest: UploadProps['customRequest'] = async ({
    file,
    onSuccess,
    onError
  }) => {
    setRunning(true)
    const client = await getOssClient()
    const suffix = file.name.slice(file.name.lastIndexOf('.'))
    try {
      const res = await client?.put(`${dir}/${Date.now()}${suffix}`, file)
      onSuccess(res || {}, file)
      await onUploaded?.(res?.url)
      message.success('上传成功')
    } catch (e) {
      onError(e)
      message.error('上传失败')
    } finally {
      setRunning(false)
    }
    return {
      abort() {
        message.info('image uploader aborted')
      }
    }
  }

  return (
    <Upload
      onChange={onFileChange}
      customRequest={onRequest}
      showUploadList={false}
    >
      <Button loading={running}>
        <UploadOutlined />
        {buttonText || '上传'}
      </Button>
    </Upload>
  )
}

export default UploadButton
