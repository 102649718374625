import React, { FC } from 'react'
import { Button } from 'antd'
import useAxios from 'axios-hooks'
import { MeetingDetailQuery, UpMeetingBudgetType } from '../graphql'
import dayjs from 'dayjs'
import { Store } from 'antd/lib/form/interface'
import { formatQuotations, generateTotalObj } from '../pages/MeetingDetail/util'

const DownloadQuotationBill: FC<{
  meeting: MeetingDetailQuery['meetingDetail']
  formValues: Store
}> = ({ meeting, formValues }) => {
  const [{ loading }, generateExcel] = useAxios(
    {
      baseURL: process.env.FAAS_SERVICE,
      url: 'bms-excel-generate',
      method: 'POST'
    },
    {
      manual: true
    }
  )

  const generate = async () => {
    const orders =
      meeting?.budgets.items.find(
        (budget) =>
          budget.type === UpMeetingBudgetType.ShortDistanceTransportationFee
      )?.orders || []

    const total = formatQuotations(orders, formValues).reduce(
      (prev, cur) => {
        prev.price += (cur.num || 0) * cur.price
        prev.num += cur.num || 0
        return prev
      },
      {
        price: 0,
        num: 0
      }
    )

    const { carTotal, poTotal, taxTotal, totalAmount } = generateTotalObj(
      total.price,
      total.num
    )
    const quotations =
      orders.map((order, index) => {
        const attr = order.attributes
        return [
          attr?.type,
          attr?.carType,
          attr?.num,
          attr?.unit,
          formValues[`quotation${index}.unitPrice`],
          formValues[`quotation${index}.unitPrice`] * attr?.num,
          attr?.note
        ]
      }) || []
    const { data } = await generateExcel({
      data: {
        templateName: 'demand-quotation-template',
        data: [
          meeting?.name,
          dayjs(meeting?.startTime).format('YYYY-MM-DD'),
          dayjs(meeting?.endTime).format('YYYY-MM-DD'),
          meeting?.city?.name,
          meeting?.bmsMeetingHotel?.name,
          meeting?.outsideAttenderNum,
          '商宴通',
          meeting?.type,
          meeting?.code,
          meeting?.ownerName,
          meeting?.department,
          meeting?.insideAttenderNum,
          '付璀 （13764334567）',
          // 用车list
          quotations,
          carTotal,
          poTotal,
          '6%',
          taxTotal,
          totalAmount
        ]
      }
    })
    window.open(data)
  }
  return (
    <Button loading={loading} onClick={generate}>
      下载报价单
    </Button>
  )
}

export default DownloadQuotationBill
