import React, { FC } from 'react'
import { Button, Modal, message } from 'antd'
import {
  useCancelMeetingMutation,
  MeetingListQuery,
  UpMeetingStatus,
  UpMeetingQuotationStatus
} from '../graphql'
import { Link } from 'react-router-dom'

type Props = {
  meeting: MeetingListQuery['meetings']['items'][0]
  onFinish?: (result?: boolean) => void
}

export const EditMeetingButton: FC<Props> = ({ meeting }) => {
  if (meeting.status !== UpMeetingStatus.UMeetingQuotation) {
    return (
      <Button
        type='link'
        onClick={() => {
          Modal.warn({
            maskClosable: true,
            content: '会议已经确认报价，如果需要修改请联系商宴通进行修改'
          })
        }}
      >
        编辑
      </Button>
    )
  }
  return (
    <Link to={`/meetingEdit/${meeting.id}`}>
      <Button type='link'>编辑</Button>
    </Link>
  )
}


export const CheckCarUsageAnalyticsButton: FC<Props> = ({ meeting }) => {
  return (
    <Link to={`/carUsageAnalyticsDetail/${meeting.id}`}>
      <Button type='link'>查看报告</Button>
    </Link>
  )
}
export const CancelMeetingButton: FC<Props> = ({ meeting, onFinish }) => {
  const [, cancelMeetingMutation] = useCancelMeetingMutation()

  const confirmCancelMeeting = async (): Promise<void> => {
    if (!meeting.id) {
      message.error('no meetingId')
      return
    }

    Modal.confirm({
      title: '取消会议',
      maskClosable: true,
      content: '确认取消会议',
      onOk: async () => {
        const { data, error } = await cancelMeetingMutation({
          meetingId: meeting.id!
        })
        if (error) {
          message.error(error.message)
          onFinish && onFinish(false)
          return
        }

        onFinish && onFinish(data?.success)

        if (data?.success) {
          message.success('取消成功')
          return
        }
      }
    })
  }

  return (
    <Button onClick={confirmCancelMeeting} type='link' danger>
      取消
    </Button>
  )
}
