import React, { FC } from 'react'
import { Table, Tag } from 'antd'
import { MeetingDetailQuery } from '../../graphql'

const AttenderTable: FC<{
  attenders?: NonNullable<MeetingDetailQuery['meetingDetail']>['attenders']
}> = ({ attenders }) => {
  return (
    <Table
      dataSource={attenders?.filter(({ type }) => type === 'OUTSIDE') || []}
    >
      <Table.Column
        dataIndex='attributes'
        title='HCP姓名'
        render={(att) => (
          <>
            {att?.name} {att?.isTemp && <Tag color='blue'>临时</Tag>}
          </>
        )}
      />
      <Table.Column
        dataIndex='attributes'
        title='职称'
        render={(att) => att?.title}
      />

      <Table.Column
        dataIndex='attributes'
        title='医院'
        render={(att) => att?.hospital}
      />
      <Table.Column
        dataIndex='attributes'
        title='科室'
        render={(att) => att?.department}
      />
      <Table.Column
        dataIndex='attributes'
        title='出席者手机'
        render={(att) => att?.mobile}
      />
      <Table.Column
        dataIndex='attributes'
        title='BMS对接人姓名'
        render={(att) => att?.inviterName}
      />
      <Table.Column
        dataIndex='attributes'
        title='BMS对接人手机'
        render={(att) => att?.inviterMobile}
      />
    </Table>
  )
}

export default AttenderTable
