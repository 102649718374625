import React, { useState } from 'react'
import {
  useMeetingDetailQuery,
  MeetingDetailQuery,
  UpMeetingStatus,
  UpMeetingQuotationStatus,
  useConfirmQuotationsMutation,
  useUploadPoMutation,
  useComfirmMeetingToFinishedMutation
} from '../../graphql'
import { useParams } from 'react-router-dom'
import {
  Skeleton,
  Card,
  Row,
  Col,
  Space,
  Tag,
  Descriptions,
  Button,
  message,
  Divider,
  Modal
} from 'antd'
import { useMeetingBaseInfo } from './util'
import ErrorView from '../../components/ErrorView'
import UploadAttenderXlsx from '../../components/UploadAttenderXlsx'
import OrdersTable from './ordersTable'
import CarOrdersTable from './carOrders'
import UploadTravelsFromExcel from '../../components/UploadTravelsFromExcel'
import UploadButton from '../../components/UploadButton'
import { useUserStore } from '../../hooks/user'
import RetreatConfirm from '../../components/RetreatConfirm'
import AttenderTable from './attenderTable'
import { DownloadOutlined } from '@ant-design/icons'
import DownloadCostBreakDown from '../../components/DownloadCostBreakDown'

type MeetingDetail = MeetingDetailQuery['meetingDetail']

const MeetingDetail: QCPage = () => {
  const { meetingId, action } = useParams()
  const userStore = useUserStore()
  const [quotationTotal, setQuotationTotal] = useState(0)
  const isView = !action
  const [
    { fetching: confirmFinishedLoading },
    confirmFinished
  ] = useComfirmMeetingToFinishedMutation()

  const [
    { fetching: confirmLoading },
    confirmQuotation
  ] = useConfirmQuotationsMutation()

  const [, uploadPO] = useUploadPoMutation()

  const [{ data, fetching, error }, refetchDetail] = useMeetingDetailQuery({
    variables: {
      meetingId
    }
  })

  const uploadPOHandler = async (url: string | undefined) => {
    if (url) {
      const { data, error } = await uploadPO({
        meetingId: meetingId,
        url
      })
      if (!data?.uploadRes || error) {
        throw new Error(error?.graphQLErrors[0]?.message || '上传失败')
      }
      refetchDetail()
    }
  }

  const onConfirmFinished = async (meetingId: string) => {
    Modal.confirm({
      content: '是否确认收货',
      onOk: async () => {
        const { data } = await confirmFinished({
          meetingId
        })

        if (data?.result) {
          message.success('确认成功')
          return refetchDetail()
        }
        message.error('确认失败')
      }
    })
  }

  const onConfirm = async (meetingId: string) => {
    const { data } = await confirmQuotation({
      meetingId
    })

    if (data?.success) {
      message.success('提交成功')
      return refetchDetail()
    }
    message.error('提交失败')
  }
  const fields = useMeetingBaseInfo(data?.meetingDetail)

  if (fetching) {
    return <Skeleton active />
  }

  if (error) {
    return (
      <ErrorView
        onRefresh={refetchDetail}
        title={error.name}
        message={error.message}
      />
    )
  }

  return (
    <Space direction='vertical'>
      <Card
        title='会议基本信息'
        loading={fetching}
        extra={
          <Tag
            color={data?.meetingDetail?.bizStatus?.color}
            style={{ fontSize: 14 }}
          >
            {data?.meetingDetail?.bizStatus?.text}
          </Tag>
        }
      >
        <Descriptions column={{ xs: 1, sm: 2, md: 4 }}>
          {fields.map((field) => (
            <Descriptions.Item key={field.label} label={field.label}>
              {field.content}
            </Descriptions.Item>
          ))}
        </Descriptions>
      </Card>
      {data?.meetingDetail?.subStatus ===
      UpMeetingQuotationStatus.UAwaitOrder ? (
        userStore.isAdmin || userStore.isCompanyBuyer ? (
          <Row align='middle'>
            <Col span={24}>
              请下载用车需求模板填完完整用车需求上传后, 获取报价
              <Divider type='vertical' />
              <Button
                type='primary'
                href='https://bms-up.oss-cn-hangzhou.aliyuncs.com/admin/xlsx-uploads/%E4%B8%8A%E4%BC%A0%E4%BC%9A%E8%AE%AE%E7%94%A8%E8%BD%A6%E9%9C%80%E6%B1%82%E6%A8%A1%E6%9D%BF.xlsx'
                target='__blank'
              >
                下载模板
              </Button>
              <Divider type='vertical' />
              <UploadTravelsFromExcel
                onResult={() => refetchDetail()}
                meeting={data.meetingDetail}
              />
            </Col>
          </Row>
        ) : null
      ) : (
        <Card title='用车需求信息' loading={fetching}>
          <OrdersTable
            meetingDetail={data?.meetingDetail}
            onQuotationSuccess={refetchDetail}
            calculatedTotal={setQuotationTotal}
          />
        </Card>
      )}

      <Card
        title='参会人信息'
        extra={
          data?.meetingDetail?.status === UpMeetingStatus.UMeetingInProgress &&
          // data.meetingDetail.subStatus !== 'U_AWAIT_COMFIRM_COMPLETE' &&
          (userStore.isAdmin || userStore.isCompanyMeetingManager) && (
            <Space direction='horizontal'>
              <Button
                href='https://bms-up.oss-cn-hangzhou.aliyuncs.com/admin/xlsx-uploads/%E5%8C%BB%E7%94%9F%E8%A1%8C%E7%A8%8B%E9%9C%80%E6%B1%82%E6%94%B6%E9%9B%86%E8%A1%A8.xlsx'
                target='__blank'
              >
                <DownloadOutlined />
                下载参会人信息模板
              </Button>
              <UploadAttenderXlsx meeting={data.meetingDetail} />

              {!data.meetingDetail.bmsPOBill && (
                <UploadButton
                  bucket='bms-up'
                  dir={`/admin/po-bills/${meetingId}`}
                  onUploaded={uploadPOHandler}
                  buttonText='上传PO单'
                />
              )}
            </Space>
          )
        }
      >
        <AttenderTable attenders={data?.meetingDetail?.attenders} />
      </Card>
      {(data?.meetingDetail?.status === UpMeetingStatus.UMeetingInProgress ||
        data?.meetingDetail?.status === UpMeetingStatus.UMeetingFinished) &&
        data.meetingDetail?.carOrders?.length > 0 && (
          <Card title='行程信息'>
            <CarOrdersTable
              quotationTotal={quotationTotal}
              orders={
                (data.meetingDetail.carOrders as {
                  [key: string]: any
                }[]) || []
              }
            />

            <Divider type='horizontal' />
            <Row>
              <Col span={24} style={{ textAlign: 'right' }}>
                <DownloadCostBreakDown
                  meeting={data.meetingDetail}
                  carOrders={data.meetingDetail.carOrders}
                />
              </Col>
            </Row>
          </Card>
        )}
      <Row>
        <Col span={24} style={{ textAlign: 'center', padding: 40 }}>
          {/* {data?.meetingDetail?.status === UpMeetingStatus.UMeetingInProgress &&
            data.meetingDetail.subStatus !== 'U_AWAIT_COMFIRM_COMPLETE' &&
            (userStore.isAdmin || userStore.isCompanyMeetingManager) && (
              <Space direction='horizontal'>
                <UploadAttenderXlsx meeting={data.meetingDetail} />
                <UploadButton
                  bucket='bms-up'
                  dir={`/admin/po-bills/${meetingId}`}
                  onUploaded={uploadPOHandler}
                  buttonText='上传PO单'
                />
              </Space>
            )} */}
          {data?.meetingDetail?.subStatus ===
            UpMeetingQuotationStatus.UAwaitConfirm &&
            isView &&
            (userStore.isAdmin || userStore.isCompanyBuyer) && (
              <Space direction='horizontal'>
                <RetreatConfirm
                  meetingId={meetingId}
                  onSuccess={refetchDetail}
                />
                <Button
                  loading={confirmLoading}
                  type='primary'
                  onClick={() => {
                    onConfirm(meetingId)
                  }}
                >
                  确认报价
                </Button>
              </Space>
            )}

          {data?.meetingDetail?.status === UpMeetingStatus.UMeetingInProgress &&
          data.meetingDetail.subStatus === 'U_AWAIT_COMFIRM_COMPLETE' &&
          (userStore.isAdmin || userStore.isCompanyMeetingManager) ? (
            <Button
              loading={confirmFinishedLoading}
              onClick={() => {
                onConfirmFinished(meetingId)
              }}
              type='primary'
            >
              确认收货
            </Button>
          ) : null}
        </Col>
      </Row>
    </Space>
  )
}

MeetingDetail.pageTitle = '会议详情'

export default MeetingDetail
