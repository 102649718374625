import React from 'react'
import omit from 'lodash.omit'

import { searchFields, useTableColumns, FieldTypes } from './data'
import { useMeetingAbnormalListQuery, BmsMeetingStatus } from '../../graphql'
import SearchTable, { useSearchTable } from '../../components/SearchTable'
import ErrorView from '../../components/ErrorView'

const ExceptionList: QCPage = () => {
  const table = useSearchTable<FieldTypes>({}, { pageSize: 15, currentPage: 1 })

  const queryVariable = {
    ...omit(table.searchParams, 'date'),
    status: BmsMeetingStatus.Finished,
    startTime:
      (table.searchParams?.date?.[0]?.startOf('day').unix() || 0) * 1000 ||
      undefined, // 0 will not be ignore by server
    endTime:
      (table.searchParams?.date?.[1]?.endOf('day').unix() || 0) * 1000 ||
      undefined // 0 will not be ignore by server
  }

  const [
    { data, error, fetching },
    refetchMeetingList
  ] = useMeetingAbnormalListQuery({
    variables: {
      query: {
        pager: omit(table.pager, 'total'),
        query: queryVariable
      }
    }
  })

  const tableColumns = useTableColumns()

  if (error) {
    return (
      <ErrorView
        onRefresh={refetchMeetingList}
        title={error.name}
        message={error.message}
      />
    )
  }

  return (
    <main>
      <SearchTable
        table={table}
        searchProps={{ fields: searchFields }}
        bordered
        loading={fetching}
        pager={data?.meetings?.pager}
        dataSource={data?.meetings?.items || []}
        size='middle'
        rowKey='id'
        columns={tableColumns}
      />
    </main>
  )
}

ExceptionList.pageTitle = '异常报告列表'

export default ExceptionList
