import React, { FC, useState } from 'react'
import { Upload, Button } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { RcFile } from 'antd/lib/upload'
import { readFile } from '../lib/readExcelFile'

export interface ExcelParseButtonProps {
  parser?: (data: any) => any
  onParsed: (data: any) => void
  loading: boolean
  sheetName?: string
  onParseError?: (e: Error) => void
  buttonText?: string
}

const ExcelParseButton: FC<ExcelParseButtonProps> = ({
  loading = false,
  sheetName = 'Sheet1',
  parser = (data) => data,
  buttonText = 'upload',
  onParsed,
  onParseError
}) => {
  const [files, updateFiles] = useState<RcFile[]>([])

  const parseFile = (file: RcFile): boolean => {
    readFile(file, sheetName).then(
      (data: any) => onParsed(parser ? parser(data) : null),
      onParseError
    )
    updateFiles([])
    return false
  }

  return (
    <Upload disabled={loading} beforeUpload={parseFile} fileList={files}>
      <Button type='primary' loading={loading}>
        <UploadOutlined /> {buttonText}
      </Button>
    </Upload>
  )
}

export default ExcelParseButton
