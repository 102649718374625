import React, { useMemo } from 'react'
import { Input, Space } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { Link } from 'react-router-dom'
import locale from 'antd/es/date-picker/locale/zh_CN'
import dayjs, { Dayjs } from 'dayjs'
import {
  CitySelector,
  MeetingStatusSelector
} from '../../components/SelectorCollection'
import DatePicker from '../../components/CustomDatePicker'
import {
  MeetingListQuery,
  UpMeetingBudgetType,
  UpMeetingStatus,
  UpMeetingQuotationStatus
} from '../../graphql'
import {
  CancelMeetingButton,
  EditMeetingButton
} from '../../components/MeetingActionButton'
import Button from 'antd/es/button'
import AuthRender from '../../components/AuthRender'
import { debug } from 'webpack'

//搜索字段声明
export const searchFields = [
  {
    name: 'status',
    label: '会议状态',
    span: 4,
    render() {
      return <MeetingStatusSelector />
    }
  },
  {
    name: 'code',
    label: '会议编号',
    span: 5,
    render() {
      return <Input allowClear />
    }
  },
  {
    name: 'name',
    label: '会议名称',
    span: 5,
    render() {
      return <Input allowClear />
    }
  },
  {
    name: 'date',
    label: '会议日期',
    span: 6,
    render() {
      return <DatePicker.RangePicker locale={locale} format='YYYY-MM-DD' />
    }
  }
]

export const useTableColumns = (props: {
  onCancel: () => void
}): ColumnsType<MeetingListQuery['meetings']['items'][0]> => {
  return [
    {
      title: '会议代码',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: '会议名称',
      dataIndex: 'name',
      key: 'name',
      render(v, meeting) {
        return <Link to={`/meeting/${meeting.id}`}>{v}</Link>
      }
    },
    {
      title: '会议城市',
      dataIndex: 'city',
      align: 'center',
      key: 'name',
      render(v) {
        return v?.name
      }
    },
    {
      title: '会议酒店',
      dataIndex: 'bmsMeetingHotel',
      key: 'hotel',
      render(v, m) {
        return v?.name || m.hotel
      }
    },
    {
      title: '会议开始时间',
      dataIndex: 'startTime',
      key: 'startTime',
      render(v) {
        return dayjs(v).format('YYYY-MM-DD')
      }
    },
    {
      title: '会议结束时间',
      dataIndex: 'endTime',
      key: 'endTime',
      render(v) {
        return dayjs(v).format('YYYY-MM-DD')
      }
    },
    {
      title: '用车趟数',
      dataIndex: 'travelOrders',
      align: 'center',
      key: 'travelOrders',
      render(_, meeting) {
        return meeting.budgets.items
          .find(
            (i) => i.type === UpMeetingBudgetType.ShortDistanceTransportationFee
          )
          ?.orders.reduce((total, current) => (total += current.num || 0), 0)
      }
    },
    {
      title: '会议状态',
      dataIndex: 'bizStatus',
      key: 'bizStatus',
      align: 'center',
      render(v) {
        return <span style={{ color: v?.color }}>{v?.text}</span>
      }
    },
    {
      title: 'PO单',
      dataIndex: 'bmsPOBill',
      key: 'bmsPOBill',
      align: 'center',
      render(v) {
        return v ? (
          <Button type='link' target='__blank' href={v}>
            查看
          </Button>
        ) : (
          '--'
        )
      }
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      render(_, meeting) {
        return (
          <AuthRender roles={['sytMeetingManager']}>
            {(user) => {
              return (
                <Space direction='horizontal'>
                  {meeting.subStatus ===
                    UpMeetingQuotationStatus.UAwaitQuotation &&
                    (user.isAdmin || user.isSYTMeetingManager) && (
                      <Link to={`/meeting/${meeting.id}/quotation`}>
                        <Button type='link'>报价</Button>
                      </Link>
                    )}
                  {(user.isAdmin || user.isCompanyMeetingManager) && (
                    <>
                      <EditMeetingButton meeting={meeting} />
                      {meeting.status !== UpMeetingStatus.UMeetingCanceled && (
                        <CancelMeetingButton
                          meeting={meeting}
                          onFinish={props.onCancel}
                        />
                      )}
                    </>
                  )}
                  {user.isCompanyBuyer && (
                    <Link to={`/meeting/${meeting.id}`}>详情</Link>
                  )}
                </Space>
              )
            }}
          </AuthRender>
        )
      }
    }
  ]
}

export type FieldTypes = {
  cityId?: string
  code?: string
  name?: string
  date?: [Dayjs | undefined, Dayjs | undefined]
}
