import React from 'react'
import { Space, Skeleton } from 'antd'
import { useParams } from 'react-router-dom'

import {
  CarBudgetChart,
  CarTypeDescription,
  BudgetTypeDescription,
  TypeChartOption,
  TypeDetailChartOption,
  BudgetChartOption,
  CarTypeChart,
} from './Sections'
import ErrorView from '../../components/ErrorView'
import { useMeetingDetailQuery } from '../../graphql'
import { formatData } from './util'

const CarUsageAnalytics: QCPage = () => {
  const { meetingId } = useParams()
  const [{ data, fetching, error }, refetchDetail] = useMeetingDetailQuery({
    variables: {
      meetingId
    }
  })
  const { typeAndBudget, typeAndSuplier } = formatData(data?.meetingDetail)
  console.log(typeAndBudget)
  // 用车类型表格
  const carTypeData = {
    reserveFive: typeAndSuplier.online.comfortable,
    reserveSeven: typeAndSuplier.online.business,
    offlineFive: typeAndSuplier.offlineteam.comfortable,
    offlineSeven: typeAndSuplier.offlineteam.business
  }
  // 用车类型饼图 配置
  const carTypeChartOption = TypeChartOption({
    onlineCount:
      typeAndSuplier.online.comfortable + typeAndSuplier.online.business,
    offlineCount:
      typeAndSuplier.offlineteam.comfortable +
      typeAndSuplier.offlineteam.business
  })
  // 用车类型详细饼图 配置
  const carTypeDetailChartOption = TypeDetailChartOption({
    reserveFive: typeAndSuplier.online.comfortable,
    reserveSeven: typeAndSuplier.online.business,
    offlineFive: typeAndSuplier.offlineteam.comfortable,
    offlineSeven: typeAndSuplier.offlineteam.business
  })


  // 用车费用表格
  const budgetTypeData = typeAndBudget

  // 用车费用饼图
  const carBudgetChartOption = BudgetChartOption(typeAndBudget)

  if (fetching) {
    return <Skeleton />
  }

  if (error) {
    return (
      <ErrorView
        onRefresh={refetchDetail}
        title={error.name}
        message={error.message}
      />
    )
  }
  return (
    <section style={{backgroundColor: '#ffffff'}}>
      <CarTypeDescription {...carTypeData} />
      <Space size={20} />
      {carTypeChartOption && (
        <CarTypeChart
          leftChartOption={carTypeChartOption}
          rightChartOption={carTypeDetailChartOption}
        />
      )}
      <Space size={20} />
      <BudgetTypeDescription dataSource={budgetTypeData} />
      <Space size={20} />
      <CarBudgetChart option={carBudgetChartOption} />
    </section>
  )
}
CarUsageAnalytics.pageTitle = '用车报表'
export default CarUsageAnalytics
