import React from 'react'
import { Input } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { Link } from 'react-router-dom'
import locale from 'antd/es/date-picker/locale/zh_CN'
import dayjs, { Dayjs } from 'dayjs'
import DatePicker from '../../components/CustomDatePicker'
import { MeetingAbnormalListQuery, CarOrderInfo } from '../../graphql'
import Button from 'antd/es/button'
//搜索字段声明
export const searchFields = [
  {
    name: 'code',
    label: '会议编号',
    span: 5,
    render() {
      return <Input allowClear />
    }
  },
  {
    name: 'name',
    label: '会议名称',
    span: 5,
    render() {
      return <Input allowClear />
    }
  },
  {
    name: 'date',
    label: '会议日期',
    span: 6,
    render() {
      return <DatePicker.RangePicker locale={locale} format='YYYY-MM-DD' />
    }
  }
]

export const useTableColumns = (): ColumnsType<
  MeetingAbnormalListQuery['meetings']['items'][0]
> => {
  return [
    {
      title: '会议代码',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: '会议名称',
      dataIndex: 'name',
      key: 'name',
      render(v, meeting) {
        return <Link to={`/exceptionDetail/${meeting.id}`}>{v}</Link>
      }
    },
    {
      title: '联系人',
      dataIndex: 'ownerName',
      key: 'ownerName',
      render(v) {
        return v || '--'
      }
    },
    {
      title: '会议城市',
      dataIndex: 'city',
      align: 'center',
      key: 'name',
      render(v) {
        return v?.name
      }
    },
    {
      title: '会议酒店',
      dataIndex: 'bmsMeetingHotel',
      key: 'hotel',
      render(v, m) {
        return v?.name || m.hotel
      }
    },
    {
      title: '会议开始时间',
      dataIndex: 'startTime',
      key: 'startTime',
      render(v) {
        return dayjs(v).format('YYYY-MM-DD')
      }
    },
    {
      title: '会议结束时间',
      dataIndex: 'endTime',
      key: 'endTime',
      render(v) {
        return dayjs(v).format('YYYY-MM-DD')
      }
    },
    {
      title: '用车行程',
      dataIndex: 'travelOrders',
      key: 'travelOrders',
      render(_, meeting) {
        return meeting.carOrders?.reduce(
          (total: number, current: CarOrderInfo) =>
            (total += current.status === 'PF_TRAVEL_FINISHED' ? 1 : 0),
          0
        )
      }
    },
    {
      title: '异常行程',
      dataIndex: 'travelOrders',
      key: 'travelOrders',
      render(_, meeting) {
        return (
          <Link to={`/exceptionDetail/${meeting.id}`}>
            <Button type='link'>{meeting.abnormalCount}</Button>
          </Link>
        )
      }
    },
    {
      title: '会议状态',
      dataIndex: 'bizStatus',
      key: 'bizStatus',
      render(v) {
        return <span style={{ color: v?.color }}>{v?.text}</span>
      }
    }
  ]
}

export type FieldTypes = {
  cityId?: string
  code?: string
  name?: string
  date?: [Dayjs | undefined, Dayjs | undefined]
}
