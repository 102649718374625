import xlsx from 'xlsx'
import { RcFile } from 'antd/lib/upload'

export const readFile = (file: RcFile, sheetName: string): any => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onload = async (event): Promise<void> => {
      const result = event.target?.result
      if (!result) {
        reject('解析失败')
        return
      }

      const wb = xlsx.read(result, { type: 'buffer', cellNF: true })
      if (!wb.Sheets[sheetName]) {
        reject('未找到Sheet')
        return
      }
      const data = xlsx.utils.sheet_to_json(wb.Sheets[sheetName], {
        raw: false,
        header: 1
      })

      resolve(data)
    }

    fileReader.readAsArrayBuffer(file)
  })
}
