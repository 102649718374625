import React from 'react'
import { useParams } from 'react-router-dom'
import { useBmsCarOrderDetailQuery } from '../../graphql'
import MeetingCard from './MeetingCard'
import MapCard from './MapCard'
import ScheduleCard from './ScheduleCard'
import ErrorView from '../../components/ErrorView'
import './index.less'

const ScheduleDetail: QCPage = () => {
  const { carOrderId } = useParams()

  const [{ data, fetching, error }, refetchDetail] = useBmsCarOrderDetailQuery({
    variables: {
      carOrderId
    }
  })

  const meetingDetail = data?.detail?.meeting
  const { schedules = [], ...orderDetail } = (data?.detail?.order || {}) as {
    [key: string]: any
  }
  // const scheduleDetail = schedules?.find((item: any) => item.id === scheduleId)

  // 单个乘车人只有一个行程
  // 多个乘车人有多个行程但是只取第一个行程就
  const scheduleDetail = schedules[0]
  const guidePoints: MapGuidePoint[] = scheduleDetail?.track

  if (error) {
    return (
      <ErrorView
        onRefresh={refetchDetail}
        title={error.name}
        message={error.message}
      />
    )
  }
  const driveId = schedules?.[0]?.drives?.[0]?.id
  return (
    <section className='schedule-detail-content'>
      <MeetingCard fetching={fetching} data={meetingDetail} />
      <ScheduleCard
        fetching={fetching}
        data={schedules}
        orderDetail={orderDetail}
      />
      {driveId ? <MapCard driveId={driveId} /> : null}
    </section>
  )
}

export default ScheduleDetail
